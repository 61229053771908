import { render, staticRenderFns } from "./MobilProvidersBiztositoPage.vue?vue&type=template&id=143c2848&scoped=true&"
import script from "./MobilProvidersBiztositoPage.vue?vue&type=script&lang=js&"
export * from "./MobilProvidersBiztositoPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143c2848",
  null
  
)

export default component.exports