<template>
  <div v-frag>
    <hero-title title="services.asuw2" :subtitle="brand.mobilProviderName"></hero-title>
    <vodafone-biztositas-form :brand="brand" :mobil-provider-id="mobilProviderId" :mobil-provider-name="mobilProviderName"></vodafone-biztositas-form>
  </div>
</template>

<script>
import HeroTitle from 'src/components/titles/HeroTitle';
import VodafoneBiztositasForm from 'src/components/forms/VodafoneBiztositasForm.vue';

export default {
  name: 'MobilProvidersBiztositoPage',
  components: {
    HeroTitle, VodafoneBiztositasForm,
  },
  props: {
    mobilProviderId: {
      type: Number,
      default: 999,
    },
    mobilProviderName: {
      type: String,
      default: 'EMPTY',
    },
  },
  async beforeMount() {
    if (this.$store.state.mobilProviderManagement.selectedBrand && this.mobilProviderId === this.$store.state.mobilProviderManagement.selectedBrand.mobilProviderId) {
      this.brand = this.$store.state.mobilProviderManagement.selectedBrand
    } else {
      this.brand = await this.getMobilProvider();
      // console.log('FIZETOS PAGE mobilProvider: ', this.brand);
    }
  },
  data() {
    return {
      brand: {},
    };
  },
  methods: {
    async getMobilProvider() {
      // console.log('getMobilProvider ...');
      return await this.$store.dispatch('getMobilProviderById', this.mobilProviderId);
    },
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
